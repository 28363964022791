
import * as strapiRuntime$4ZpNReig9H from '/vercel/path0/frontend/node_modules/@nuxt/image/dist/runtime/providers/strapi'
import * as strapiHaelsiRuntime$HqL4PnuGoK from '/vercel/path0/frontend/providers/strapiHaelsi.ts'
import * as strapiRuntime$MWY5kHqaq7 from '/vercel/path0/frontend/providers/strapiHaelsiCdn.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "strapi",
  "domains": [
    "cms.haelsi.dev",
    "cdn.haelsi.dev"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['strapi']: { provider: strapiRuntime$4ZpNReig9H, defaults: {} },
  ['strapiHaelsi']: { provider: strapiHaelsiRuntime$HqL4PnuGoK, defaults: {"baseUrl":"https://cdn.haelsi.dev"} },
  ['strapi']: { provider: strapiRuntime$MWY5kHqaq7, defaults: {"baseUrl":"https://cdn.haelsi.dev"} }
}
        